<template>
  <card>
    <h4 slot="header" class="card-title">
      Export  <span v-if="Query && Query.q">(with query)</span><span v-else> (all)</span>
    </h4>
    <drop-down>
      <p-button slot="title"
                slot-scope="{isOpen}"
                type="info"
                round
                block
                :aria-expanded="isOpen"
                :disabled="LoadingX"
                class="dropdown-toggle">
        Download
      </p-button>
      <div class="dropdown-header">Download</div>
      <button class="dropdown-item"  @click="downloadFile(StoreImportAction, 'csv')">CSV</button>
      <button class="dropdown-item" @click="downloadFile(StoreImportAction, 'json')">JSON</button>
    </drop-down>
  </card> <!-- end card -->
</template>
<script>

export default {
  components: {

  },
  props: {
    storeImportAction: {
      type: String,
      required: true
    },
    query: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    },
  },
  data () {
    return {}
  },
  mounted() {

  },
  computed: {
    StoreImportAction () {
      return this.storeImportAction;
    },
    Query () {
      return this.query;
    }
  },
  methods: {
    downloadFile(action, format) {
      this.LoadingX = true;
      this.$store.dispatch(action, {...this.Query, format})
        .then( (data)=> {
          this.file = "";
        })
        .catch( (error)=> {
          this.$notify({
            title: "Data export failed",
            message: "Data export failed. Please correct your query.",
            type: "danger",
            timeout: 0,
            horizontalAlign: "right",
            verticalAlign: "top",
            icon: 'nc-icon nc-app',
          });
        })
        .finally(() => {
          // using "finally" so even if there are errors, it stops "loading"
          //Logger.trace("disableItemGeneric finally");
          this.LoadingX = false;
        });
    }
  }
}

</script>
<style>
</style>

