<template>
    <div class="row">
      <div class="col-12">
        <h3>Sources</h3>
      </div>

      <div class="col-md-6">
        <Importer store-import-action="IMPORT_SOURCES"></Importer>
      </div>
      <div class="col-md-6">
        <Exporter store-import-action="EXPORT_SOURCES" :query="Query"></Exporter>
      </div>

      <QueryTable
        title="Sources"
        :store-actions="{fetch: 'GET_SOURCES', delete: 'REMOVE_SOURCE'}"
        document-name="Source"
        detail-path="/sources/details/"
        :table-columns="tableColumns"
        :functions="{edit: false, delete: true}"
        :query="Query"
      ></QueryTable>
    </div>
</template>
<script>
  import QueryTable from "@/components/FCXComponents/QueryTable.vue";
  import Importer from "@/components/FCXComponents/Importer.vue";
  import Exporter from "@/components/FCXComponents/Exporter.vue";
  import {queryMixin} from "@/js/mixins/query-mixin";


  export default {
    components: {
      Exporter,
      Importer,
      QueryTable
    },
    mixins: [queryMixin],
    data () {
      return {
        propsToSearch: ['_id', 'casId', 'name', 'family', 'refUserId', 'refSourceId', 'createdAt', 'updatedAt'],
        tableColumns: [
          {
            prop: '_id',
            label: 'ID',
            minWidth: 230
          },
          {
            prop: 'status',
            label: 'status',
            minWidth: 100
          },
          {
            prop: 'name',
            label: 'name',
            minWidth: 250
          },
          {
            prop: 'description',
            label: 'description',
            minWidth: 400
          },
          {
            prop: 'remarks',
            label: 'remarks',
            minWidth: 250
          },
          {
            prop: 'type',
            label: 'type',
            minWidth: 200
          },
          {
            prop: 'fcmType',
            label: 'fcmType',
            minWidth: 200
          },
          {
            prop: 'uri',
            label: 'uri',
            minWidth: 250
          },
          {
            prop: 'version',
            label: 'version',
            minWidth: 200
          },
          {
            prop: 'publicationDate',
            label: 'publicationDate',
            minWidth: 200
          },
          {
            prop: 'journalTitle',
            label: 'journalTitle',
            minWidth: 200
          },
          {
            prop: 'authors',
            label: 'authors',
            minWidth: 200
          },
          {
            prop: 'region',
            label: 'region',
            minWidth: 200
          },
          {
            prop: 'keywords',
            label: 'keywords',
            minWidth: 200
          },
          {
            prop: 'meta.extId',
            label: 'extId',
            minWidth: 100
          },
          {
            prop: 'comments',
            label: 'comments',
            minWidth: 300
          },
          {
            prop: 'tags',
            label: 'tags',
            minWidth: 200
          },
          {
            prop: 'refUserId',
            label: 'UserId',
            minWidth: 230
          },
          {
            prop: 'refProjectId',
            label: 'ProjectId',
            minWidth: 230
          },
          {
            prop: 'createdAt',
            label: 'Created',
            sortable: true,
            minWidth: 150,
            formatter: (row, column, cellValue) => {
              return new Date(cellValue).toLocaleString()
            }
          },
          {
            prop: 'updatedAt',
            label: 'Updated',
            sortable: true,
            minWidth: 150,
            formatter: (row, column, cellValue) => {
              return new Date(cellValue).toLocaleString()
            }
          },

        ],
        // only for testing purpose
        pagination: {
          perPage: 25,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
      }
    },
    mounted() {},
    computed: {},
    methods: {}

  }

</script>
<style>
</style>
