<template>
  <form ref="importRef" @submit.prevent="uploadFile(StoreImportAction)">
    <card>
      <h4 slot="header" class="card-title">
        Import
      </h4>
      <div class="row">
        <div class="col-12">
          <slot></slot>
        </div>
      </div>

      <input type="file" @change="handleFileUpload( $event )"/>
      <!--
      <div class="row mt-3" v-if="file && file.type === 'text/csv'">
        <div class="col-12">
          <p-checkbox v-model="withBOM" name="withBOM" value="withBOM" required>withBOM (excel CSV export)</p-checkbox>
        </div>
      </div>
      -->

      <p-button native-type="submit"  type="info" round class="mb-3">Upload</p-button>
      <!--
      <div v-if="resultMsg">
        <pre>{{ resultMsg }}</pre>
      </div>
      -->
    </card> <!-- end card -->
  </form>
</template>
<script>
import Swal from "sweetalert2";
import {Select} from "element-ui";

export default {
  components: {
    [Select.name]: Select,
  },
  props: {
    storeImportAction: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      file: '',
      resultMsg: null
    }
  },
  mounted() {

  },
  computed: {
   StoreImportAction () {
      return this.storeImportAction;
    }
  },
  methods: {
    handleFileUpload(event) {
      this.file = event.target.files[0];
      this.resultMsg = null;
    },
    uploadFile(action) {
      if(!this.file) {
        const swalWithBootstrapButtons8 = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-success",
          },
          buttonsStyling: false,
        });
        swalWithBootstrapButtons8.fire({
          title: "No file selected!",
          html: `<p>
                    Please select a file to upload
                    </p>`,
        });
        return;
      }
      this.LoadingX = true;
      let formData = new FormData();
      formData.append('uploaded_file', this.file);
      //formData.append('withBOM', this.withBOM);

      //this.file = this.$refs.file.files[0];
      this.$store.dispatch(action, formData)
        .then((res)=> {
          this.resultMsg = res;
          this.file = "";

          const swalWithBootstrapButtons8 = Swal.mixin({
            customClass: {
              confirmButton: "btn btn-success",
              cancelButton: "btn",
            },
            buttonsStyling: false,
          });
          swalWithBootstrapButtons8.fire({
            title: res.result.model + " import successful!",
            showCancelButton: true,
            cancelButtonText: "Reload",
            html: `<p>
                      Counted ${res.result.counterData} records<br>
                      Flagged ${res.result.counterDataFlagged} records<br>
                      Imported ${res.result.counterDataImported} records<br>
                      Updated ${res.result.counterDataUpdated} records<br>
                      Omitted ${res.result.data.omitted.length} records<br>
                      Errors ${res.result.counterErrors} records
                      </p>`,
            confirmButtonText: "Download Report"
          })
            .then((result) => {
              if (result.value) {
                this.downloadImportReport(res);
              }
              location.reload();
            })
      })
        .catch((err)=> {
          this.resultMsg = err;
          const respData = err && err.response && err.response.data;
          const swalWithBootstrapButtons8 = Swal.mixin({
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
          swalWithBootstrapButtons8.fire({
            title: "Import failed!",
            html: `<p>
                      ${err}<br><br>
                      Details:<br>
                      ${respData && respData.msg ? respData.msg : ""}<br>
                      </p>`,
          });
        })
        .finally(()=> {
          this.LoadingX = false;
          this.$refs.importRef.reset();
          this.file = "";
        })
    },
    downloadImportReport(res) {
      Logger.debug("downloadImportReport");
      const tmpArray = this.StoreImportAction.split("_");
      let name = "generic"
      if(tmpArray.length >1) {
        name = this.StoreImportAction.split("_")[1];
      }
      const reportDoc = JSON.stringify(res);
      this.download(reportDoc, "report-import-" + name + ".json")
    },
    download(data, filename) {
      Logger.debug("download", data, filename);
      const blob = new Blob([data], { type: 'plain/text' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = filename;
      link.click()
      URL.revokeObjectURL(link.href)
    }
  }
}

</script>
<style>
</style>

