<template>
  <div class="col-12 fcx-query-table">
    <div class="card">
      <div class="card-header">
        <h5 class="title">Data ({{Total}} entries)</h5>
        <!--
        <pre>{{this.pagination}}</pre>
        from {{From}} to {{To}}
        -->


      </div>
      <div class="card-body ">
          <div class="row" v-if="Query && Query.q">
              <div class="col-12">
                  Query <pre>{{Query}}</pre>
              </div>
          </div>

        <div class="row">
          <div class="col-6">
            <el-select
              class="select-default"
              v-model="pagination.perPage"
              placeholder="Per page">
              <el-option
                class="select-default"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
          </div>
          <div class="col-6">
            <div class="pull-right">
              <!--
              <fg-input class="input-sm"
                        placeholder="Search"
                        v-model="searchQuery"
                        addon-right-icon="nc-icon nc-zoom-split">
              </fg-input>
              -->
            </div>
          </div>
          <div class="col-12 mt-2" v-if="PagedData && PagedData.length > 0">
            <el-table
              ref="multipleTable"
              :data="PagedData"
              @selection-change="handleSelectionChange"
              stripe
              border
              max-height="800px"
              style="width:100%">

                <el-table-column type="expand" width="35">
                    <template slot-scope="props">
                        <p>Data: <pre>{{ props.row }}</pre></p>
                    </template>
                </el-table-column>

                <el-table-column
                        type="selection"
                        width="35"
                >
                </el-table-column>



              <el-table-column v-for="column in tableColumns"

                               :key="column.label"
                               :width="column.minWidth"
                               :prop="column.prop"
                               :label="column.label"
                               :type="column.type"
                               :sortable="column.sortable"
                               :formatter="column.formatter"
              >
              </el-table-column>
              <el-table-column
                :min-width="120"
                fixed="right"
                class-name="td-actions text-right"
                label="Actions">
                <template v-slot="props">
                  <p-button v-if="functions.edit"
                            type="success" size="sm" icon @click="handleEdit(props.$index, props.row)">
                    <i class="fa fa-edit"></i>
                  </p-button>
                  <p-button v-if="functions.delete"
                            type="danger" size="sm" icon @click="handleDelete(props.$index, props.row)">
                    <i class="fa fa-trash"></i>
                  </p-button>

                </template>
              </el-table-column>
            </el-table>
              <!-- test only
              <div style="margin-top: 20px">
                  <p-button @click="toggleSelection([PagedData[1], PagedData[2]])">Toggle selection status of second and third rows</p-button>
                  <p-button @click="toggleSelection()">Clear selection </p-button>
              </div>
              -->
          </div>
          <div class="col-6 pagination-info">
            <p class="category">
                Showing {{From + 1}} to {{To}} of {{Total}} entries
                <span v-if="SelectedRows.length > 0" > - Selected {{ SelectedRows.length }} rows</span>
            </p>
          </div>
          <div class="col-6">
            <p-pagination class="float-right"
                          v-model="pagination.currentPage"
                          :per-page="pagination.perPage"
                          :total="pagination.total"
                          @input="fetchData"
            >
            </p-pagination>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import {Table, TableColumn, Select, Option} from 'element-ui'
import PPagination from "@/components/UIComponents/Pagination.vue";
import Swal from "sweetalert2";
Vue.use(Select)
Vue.use(Option)
Vue.use(Table)
Vue.use(TableColumn);

export default {
  components: {
    PPagination
  },
  props: {
    title: {
      type: String,
      required: true
    },
    query: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    },
    tableColumns: {
      type: Array,
      required: true,
      default() {
        return [
            {
              prop: '_id',
              label: 'ID',
              minWidth: 100
            },
            {
              prop: 'casId',
              label: 'casId',
              minWidth: 150
            },
            {
              prop: 'name',
              label: 'name',
              minWidth: 250
            },
            {
              prop: 'description',
              label: 'description',
              minWidth: 400
            },
            {
              prop: 'refUserId',
              label: 'UserId',
              minWidth: 100
            },
            {
              prop: 'refProjectId',
              label: 'ProjectId',
              minWidth: 100
            },
            {
              prop: 'createdAt',
              label: 'createdAt',
              minWidth: 100
            },
            {
              prop: 'updatedAt',
              label: 'updatedAt',
              minWidth: 100
            }
          ]
      }
    },
    propsToSearch: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    },
    pagination: {
      type: Object,
      required: false,
      default() {
        return {
            perPage: 10,
            currentPage: 1,
            perPageOptions: [5, 10, 25, 50],
            total: 0
        }
      }
    },
    functions: {
      type: Object,
      required: false,
      default() {
        return {
          edit: false,
          delete: true
        }
      }
    },
    documentName: {
      type: String,
      required: true
    },
    detailPath: {
      type: String,
      required: false,
      default() {
        return '';
      }
    },
    storeActions: {
      type: Object,
      required: false,
      default() {
        return {
          fetch: 'fetch',
          delete: 'delete'
        }
      }
    }
  },
  data () {
    return {
      /*
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      */
      //propsToSearch: ['_id', 'casId', 'name', 'family', 'refUserId', 'refSourceId', 'createdAt', 'updatedAt'],
      /*
      tableColumns: [
        {
          prop: '_id',
          label: 'ID',
          minWidth: 100
        },
        {
          prop: 'casId',
          label: 'casId',
          minWidth: 150
        },
        {
          prop: 'name',
          label: 'name',
          minWidth: 250
        },
        {
          prop: 'description',
          label: 'description',
          minWidth: 400
        },
        {
          prop: 'refUserId',
          label: 'UserId',
          minWidth: 100
        },
        {
          prop: 'refProjectId',
          label: 'ProjectId',
          minWidth: 100
        },
        {
          prop: 'createdAt',
          label: 'createdAt',
          minWidth: 100
        },
        {
          prop: 'updatedAt',
          label: 'updatedAt',
          minWidth: 100
        },

      ],
      */
      searchQuery: '',
      tableData: [],
      selectedRows: []
    }
  },
  mounted() {
    Logger.debug("QueryTable mounted", this.Query);
    this.countData();
  },
  computed: {
    Query() {
      return this.query;
    },
    DocumentName () {
      return this.documentName;
    },
    DetailsPath () {
      return this.detailPath;
    },
    FetchStoreAction () {
      return this.storeActions.fetch;
    },
    FetchStoreDelete () {
      return this.storeActions.delete;
    },
    PagedData () {
      return this.tableData;
    },
    SelectedRows: {
      get() {
        return this.selectedRows;
      },
      set(val) {
        this.selectedRows = val;
      }
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.PagedData}
     */
    QueriedData () {
      Logger.debug("QueriedData", this.From, this.To)
      if (!this.searchQuery) {
        /*
        this.pagination.total = this.count
        return this.PagedData*/
        //this.fetchData(this.From, this.To);
        return this.PagedData;
      }
      let result = this.tableData
        .filter((row) => {
          let isIncluded = false
          for (let key of this.propsToSearch) {
            let rowValue = row[key].toString()
            if (rowValue.includes && rowValue.includes(this.searchQuery)) {
              isIncluded = true
            }
          }
          return isIncluded
        })
      this.pagination.total = result.length
      return result.slice(this.From, this.To)
    },
    To () {
      let highBound = this.From + this.pagination.perPage
      if (this.Total < highBound) {
        highBound = this.Total
      }
      return highBound
    },
    From () {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    Total () {
      /*
      this.pagination.total = this.tableData.length
      return this.tableData.length
      */
      return this.pagination.total;
    }
  },
  watch: {
    Query: {
      handler() {
        this.countData();
        this.fetchData()
      },
      deep: true
    }
  },
  methods: {
    /**
     * Fetch data based on current page and perPage limit and set tableData reactive var.
     * @param query
     */
    countData() {
      // set total
      this.$store.dispatch(this.FetchStoreAction, {
        t: "count",
        q: this.Query.q || "{}"
      }).then((data)=> {
        this.pagination.total = data.count;
      }).catch((error)=> {
        Logger.debug("error", error);
        this.$notify({
          title: "Data count failed",
          message: "Data count failed. Please correct your query.",
          type: "danger",
          timeout: 0,
          horizontalAlign: "right",
          verticalAlign: "top",
          icon: 'nc-icon nc-app',
        });
      })
    },
    fetchData() {
      //console.warn("fetchData", from, this.pagination.perPage, this.From, this.To)
      //const query =  {"name": "FCCdb"};
      const query = this.Query;
      Logger.debug("fetchData query", query)
      const payload = {
        q: query.q || "{}", //JSON.stringify(query.q),
        sk: this.From, //(from-1) * this.pagination.perPage,
        l: this.pagination.perPage
      }
      Logger.debug("fetchData payload", payload)
      this.$store.dispatch(this.FetchStoreAction, payload)
        .then(data=> {
          this.tableData = data;
        })
        .catch(error => {
          Logger.debug("error", error);
          this.$notify({
            title: "Data fetch failed",
            message: "Data fetch failed. Please correct your query.",
            type: "danger",
            timeout: 0,
            horizontalAlign: "right",
            verticalAlign: "top",
            icon: 'nc-icon nc-app',
          });
        })
        .finally (() => {
          this.LoadingX = false;
        })
    },
    handleEdit (index, row) {
      // TODO generalize - not only users
      const userPath = this.DetailsPath + row._id;
      this.$router.push(userPath)
    },
    handleDelete (index, row) {
      Swal.fire({
        title: "Are you sure?",
        text: "You are about to delete this " + this.DocumentName + "! You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#6D6E70",
        confirmButtonText: "Yes, delete it!"
      }).then((result) => {
        if (result.value) {
          this.LoadingX = true;
          this.$store.dispatch(
            this.FetchStoreDelete,
            row._id
          )
            .then(data => {
              this.$notify({
                title: this.DocumentName + " deleted",
                message: this.DocumentName + " was deleted successfully",
                type: "success",
                timeout: 3000,
                horizontalAlign: "right",
                verticalAlign: "top",
                icon: 'nc-icon nc-app',
              });
            })
            .catch(error => {
              Logger.debug("error", error);
              this.$notify({
                title: this.DocumentName + " deletion failed",
                message: this.DocumentName + " was not deleted.",
                type: "danger",
                timeout: 3000,
                horizontalAlign: "right",
                verticalAlign: "top",
                icon: 'nc-icon nc-app',
              });

            })
            .finally(() => {
              this.LoadingX = false;
              this.countData();
              //this.fetchData();
            })
        }
      });


    },
    // not used
    TableKeys(storeX) {
      let tableKeys = [],
        firstEntry = storeX[0];
      for (const [key, value] of Object.entries(firstEntry)) {
        Logger.debug(`${key}: ${value}`);
        tableKeys.push(key);
      }
      return tableKeys;
    },

    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.SelectedRows = val;
    }
  }
}

</script>
<style>
</style>

