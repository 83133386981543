<template>
    <div class="row">
      <div class="col-12">
        <h3>Mappings (not functional yet - still in 'mapping-config.json' file)</h3>
      </div>
      <div class="col-md-6">
        <button
          v-if=""
          type="submit"
          class="btn btn-success btn-fill btn-wd"
          @click.prevent="saveMapping"
        >
          Save Mapping
        </button>
      </div>
      <div class="col-md-6">
        <Exporter store-import-action="EXPORT_MAPPINGS"></Exporter>
      </div>

      <div class="col-12">
        <div id="jsoneditor"></div>
      </div>
    </div>
</template>
<script>

import { JSONEditor } from "vanilla-jsoneditor";
import Swal from "sweetalert2";
import Exporter from "@/components/FCXComponents/Exporter.vue";
  export default {
    components: {
      Exporter

    },
    data () {
      return {
        editor: undefined,
        content: {
          text: undefined,
          json: {}
        }
      }
    },
    mounted() {

      this.$store.dispatch('GET_LATEST_MAPPING')
        .then((response) => {
          Logger.debug("response", response)
          this.content = {
            text: undefined,
            json: response
          };

          // https://github.com/DavidGarciaCat/JSON-Editor-Online/blob/master/docs/api.md
          let content = this.content;
          this.editor = new JSONEditor({
            target: document.getElementById('jsoneditor'),
            props: {
              content,
              mode: 'text',
              modes: ['tree', 'text'], // allowed modes
              onChange: (updatedContent, previousContent, { contentErrors, patchResult }) => {
                // content is an object { json: JSONData } | { text: string }
                Logger.debug('onChange', { updatedContent, previousContent, contentErrors, patchResult })
                this.content = updatedContent
                //content = updatedContent
              }
            }
          })

        })
        .catch((error) => {
          console.error("error", error)
        });

    },
    computed: {},
    methods: {
      saveMapping() {
        Swal.fire({
          title: "Are you sure?",
          text: "You are about to save the new mapping.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#6D6E70",
          confirmButtonText: "Yes, save mapping"
        }).then((result) => {
          if (result.value) {
            Logger.debug('saveMapping', this.content)
            let content = {};
            if(this.content) {
              if(this.content.json) {
                content = JSON.parse(JSON.stringify(this.content.json));
              }
              if(this.content.text) {
                content = JSON.parse(this.content.text);
              }
            }
            delete content.__v;
            delete content._id;
            delete content.updatedAt;
            delete content.createdAt;

            this.$store.dispatch('CREATE_MAPPING', content)
              .then((response) => {
                Logger.debug("response", response)
                /*
                this.content = {
                  text: undefined,
                  json: response
                };
                 */

                this.editor.set({json: response})
              })
              .catch((error) => {
                console.error("error", error)
              });
          }
        })
      },
    }
  }

</script>
<style>
</style>
