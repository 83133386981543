<template>
    <div class="row">
      <div class="col-12">
        <h3>Master</h3>
      </div>

      <div class="col-md-6">
        <card>
          <h4 slot="header" class="card-title">
            Generate
          </h4>
          <fg-input v-model="chemsIdInput" placeholder="Chems MongoDB Ids (space seperated)"></fg-input>
          <p-checkbox v-model="options.connectDB">dis/connectDB</p-checkbox>
          <p-checkbox v-model="options.cleanMaster">cleanMaster (either clean all master documents or only the one given in ids field)</p-checkbox>
          <p-checkbox v-model="options.overwrite">overwrite</p-checkbox>
          <p-checkbox v-model="options.sandboxMode">sandboxMode (do not write to db and only show results)</p-checkbox>

          <p-button @click="generateMaster" type="info" round>Generate Masters</p-button>
          <div v-if="resultMsg">
            <pre>{{ resultMsg }}</pre>
          </div>
        </card> <!-- end card -->
      </div>
      <div class="col-md-6">
        <Exporter store-import-action="EXPORT_MASTERS" :query="Query"></Exporter>
      </div>

      <QueryTable
        title="Master"
        :store-actions="{fetch: 'GET_MASTERS', delete: 'REMOVE_MASTER'}"
        document-name="Master"
        detail-path="/masters/details/"
        :table-columns="tableColumns"
        :functions="{edit: false, delete: true}"
        :query="Query"
      >
      </QueryTable>
    </div>
</template>
<script>
  import Swal from "sweetalert2";
  import QueryTable from "@/components/FCXComponents/QueryTable.vue";
  import Exporter from "@/components/FCXComponents/Exporter.vue";
  import {queryMixin} from "@/js/mixins/query-mixin";

  export default {
    components: {
      Exporter,
      QueryTable

    },
    mixins: [queryMixin],
    data () {
      return {
        resultMsg: null,
        tableColumns: [
          {
            prop: '_id',
            label: 'ID',
            minWidth: 230
          },
          {
            prop: 'status',
            label: 'status',
            minWidth: 70
          },
          {
            prop: 'slug',
            label: 'slug',
            minWidth: 200
          },
          {
            prop: 'name',
            label: 'name',
            minWidth: 250
          },
          {
            prop: 'cas.id',
            label: 'cas',
            minWidth: 100
          },
          {
            prop: 'refUserId',
            label: 'UserId',
            minWidth: 230
          },
          {
            prop: 'refProjectId',
            label: 'ProjectId',
            minWidth: 230
          },
          {
            prop: 'refSourceId',
            label: 'SourceId',
            minWidth: 230
          },
          {
            prop: 'createdAt',
            label: 'Created',
            sortable: true,
            minWidth: 150,
            formatter: (row, column, cellValue) => {
              return new Date(cellValue).toLocaleString()
            }
          },
          {
            prop: 'updatedAt',
            label: 'Updated',
            sortable: true,
            minWidth: 150,
            formatter: (row, column, cellValue) => {
              return new Date(cellValue).toLocaleString()
            }
          },

        ],
        chemsIdInput: "",
        options: {
          chemIds: [],
          connectDB: false,
          cleanMaster: false, // either clean all master data before or only
          overwrite: false, // TODO implement resp. check what is needed
          sandboxMode: false // TODO add instead of writing a master one could just stream and/or return values.
        }
      }
    },
    mounted() {},
    computed: {},
    methods: {
      generateMaster() {
        Swal.fire({
          title: "Are you sure?",
          text: "You are about to generate the master table! You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#6D6E70",
          confirmButtonText: "Yes, generate!"
        }).then((result) => {
          if (result.value) {
            this.LoadingX = true;
            this.options.chemIds = [];
            if(this.chemsIdInput.trim()) {
              const chemIds = this.chemsIdInput.trim().split(" ");
              this.options.chemIds = chemIds;
            }
            Logger.debug("this.options", this.options)

            this.$store.dispatch("GENERATE_MASTER", this.options)
              .then((data)=> {
                this.resultMsg = data;

                const swalWithBootstrapButtons8 = Swal.mixin({
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                  buttonsStyling: false,
                });
                swalWithBootstrapButtons8.fire({
                  title: "Generation successful!",
                  html: `<p>
                      Counted ${data.counterData} records<br>
                      Generated ${data.counterDataGenerated} records<br>
                      Omitted ${data.omitted.length} records<br>
                      Errors ${data.errors.length} records
                      </p>`,
                });
              })
              .finally(()=> {
                this.LoadingX = false;
              })
          }
        });

      },
    }
  }

</script>
<style>
</style>
