<template>
    <div class="row">
      <div class="col-12">
        <h3>Chems</h3>
      </div>

      <div class="col-md-6">
        <Importer store-import-action="IMPORT_CHEMS"></Importer>
      </div>
      <div class="col-md-6">
        <Exporter store-import-action="EXPORT_CHEMS" :query="Query"></Exporter>
      </div>

      <QueryTable
        title="Chems"
        :store-actions="{fetch: 'GET_CHEMS', delete: 'REMOVE_CHEM'}"
        document-name="Chem"
        detail-path="/chems/details/"
        :table-columns="tableColumns"
        :functions="{edit: false, delete: true}"
        :query="Query"
      >
      </QueryTable>
    </div>
</template>
<script>
  import QueryTable from "@/components/FCXComponents/QueryTable.vue";
  import Importer from "@/components/FCXComponents/Importer.vue";
  import Exporter from "@/components/FCXComponents/Exporter.vue";
  import {queryMixin} from "@/js/mixins/query-mixin";
   export default {
    components: {
      Exporter,
      Importer,
      QueryTable
    },
     mixins: [queryMixin],
     data () {
      return {
        tableColumns: [
          {
            prop: '_id',
            label: 'ID',
            minWidth: 230
          },
          {
            prop: 'status',
            label: 'status',
            minWidth: 100
          },
          {
            prop: 'slug',
            label: 'slug',
            minWidth: 200
          },
          {
            prop: 'name',
            label: 'name',
            minWidth: 250
          },
          {
            prop: 'cas.id',
            label: 'cas',
            minWidth: 200
          },
          {
            prop: 'cfsan.id',
            label: 'cfsan',
            minWidth: 200
          },
          {
            prop: 'uri',
            label: 'uri',
            minWidth: 200
          },
          {
            prop: 'fcm_inventory',
            label: 'fcm_inventory',
            minWidth: 200
          },
          {
            prop: 'synonyms',
            label: 'synonyms',
            minWidth: 400
          },
          {
            prop: 'duplicates',
            label: 'duplicates',
            minWidth: 400
          },
          // TODO add to children row and/or to row buttons
          {
            prop: 'comments',
            label: 'comments',
            minWidth: 400
          },
          // TODO add to children row and/or to row buttons
          {
            prop: 'tags',
            label: 'tags',
            minWidth: 400
          },
          {
            prop: 'meta.extId',
            label: 'extId',
            minWidth: 200
          },
          {
            prop: 'refUserId',
            label: 'UserId',
            minWidth: 230
          },
          {
            prop: 'refProjectId',
            label: 'ProjectId',
            minWidth: 230
          },
          {
            prop: 'createdAt',
            label: 'Created',
            sortable: true,
            minWidth: 150,
            formatter: (row, column, cellValue) => {
              return new Date(cellValue).toLocaleString()
            }
          },
          {
            prop: 'updatedAt',
            label: 'Updated',
            sortable: true,
            minWidth: 150,
            formatter: (row, column, cellValue) => {
              return new Date(cellValue).toLocaleString()
            }
          },

        ]
      }
    },
    mounted() {},
    computed: {},
    methods: {}
  }

</script>
<style>
</style>
