<template>
    <div class="row">
      <div class="col-12">
        <h3>Projects</h3>
      </div>

      <div class="col-md-6">
        <Importer store-import-action="IMPORT_PROJECTS"></Importer>
      </div>
      <div class="col-md-6">
        <Exporter store-import-action="EXPORT_PROJECTS" :query="Query"></Exporter>
      </div>


      <QueryTable
        title="Projects"
        :store-actions="{fetch: 'GET_PROJECTS', delete: 'REMOVE_PROJECT'}"
        document-name="Project"
        detail-path="/projects/details/"
        :table-columns="tableColumns"
        :functions="{edit: false, delete: true}"
        :query="Query"
      ></QueryTable>
    </div>
</template>
<script>
  import QueryTable from "@/components/FCXComponents/QueryTable.vue";
  import Importer from "@/components/FCXComponents/Importer.vue";
  import Exporter from "@/components/FCXComponents/Exporter.vue";
  import {queryMixin} from "@/js/mixins/query-mixin";
  export default {
    components: {
      Exporter,
      Importer,
      QueryTable
    },
    mixins: [queryMixin],
    data () {
      return {
        tableColumns: [
          {
            prop: '_id',
            label: 'ID',
            minWidth: 230
          },
          {
            prop: 'name',
            label: 'name',
            minWidth: 200
          },
          {
            prop: 'description',
            label: 'description',
            minWidth: 250
          },
          {
            prop: 'uri',
            label: 'uri',
            minWidth: 150
          },
          {
            prop: 'tags',
            label: 'tags',
            minWidth: 150
          },
          {
            prop: 'refUserId',
            label: 'UserId',
            minWidth: 230
          },
          {
            prop: 'createdAt',
            label: 'Created',
            sortable: true,
            minWidth: 150,
            formatter: (row, column, cellValue) => {
              return new Date(cellValue).toLocaleString()
            }
          },
          {
            prop: 'updatedAt',
            label: 'Updated',
            sortable: true,
            minWidth: 150,
            formatter: (row, column, cellValue) => {
              return new Date(cellValue).toLocaleString()
            }
          },

        ]
      }
    },
    mounted() {},
    computed: {},
    methods: {}
  }

</script>
<style>
</style>
