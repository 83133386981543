<template>
    <div class="row">
      <div class="col-12">
        <h3>Users</h3>
      </div>

      <div class="col-md-6">
        <Importer store-import-action="IMPORT_USERS">
          It is strongly advised to not use any real passwords during import.<br>
          While import of new users (status flag = "new") will automatically encrypt passwords, updates  (status flag = "update") will not.<br>
          In the case you update users, do not change the password.<br>
          You can change the password though, but you need to tell the user to use the resetPassword function.
        </Importer>
      </div>
      <div class="col-md-6">
        <Exporter store-import-action="EXPORT_USERS" :query="Query"></Exporter>
        <card>
          <p-button @click="inviteUsers" type="info" round :disabled="LoadingX">invite Users to join FCX</p-button>
        </card>
      </div>

      <QueryTable
        title="Users"
        :store-actions="{fetch: 'GET_USERS', delete: 'DELETE_USER'}"
        document-name="User"
        detail-path="/users/details/"
        :table-columns="tableColumns"
        :functions="{edit: true, delete: true}"
        :query="Query"
      >
      </QueryTable>
    </div>
</template>
<script>
  import QueryTable from "@/components/FCXComponents/QueryTable.vue";
  import Importer from "@/components/FCXComponents/Importer.vue";
  import Exporter from "@/components/FCXComponents/Exporter.vue";
  import adminAPI from "@/js/api/admin";
  import Swal from "sweetalert2";
  import {queryMixin} from "@/js/mixins/query-mixin";

  export default {
    components: {
      Exporter,
      Importer,
      QueryTable
    },
    mixins: [queryMixin],
    data () {
      return {
        tableColumns: [
          {
            prop: '_id',
            label: 'ID',
            minWidth: 230
          },
          {
            prop: 'organisation',
            label: 'organisation',
            sortable: true,
            minWidth: 200
          },
          {
            prop: 'type',
            label: 'Type',
            sortable: true,
            minWidth: 200
          },
          {
            prop: 'name',
            label: 'name',
            sortable: true,
            minWidth: 200
          },
          {
            prop: 'email',
            label: 'email',
            sortable: true,
            minWidth: 250
          },
          /*
          {
            prop: 'password',
            label: 'password',
            minWidth: 100
          },
          */
          {
            prop: 'role',
            label: 'role',
            sortable: true,
            minWidth: 130
          },
          {
            prop: 'options.confirmed',
            label: 'confirmed',
            minWidth: 140,
            sortable: true,
            formatter: (row, column, cellValue) => {
              return cellValue ? 'Yes' : 'No'
            }
          },
          {
            prop: 'options.disabled',
            label: 'disabled',
            minWidth: 130,
            sortable: true,
            formatter: (row, column, cellValue) => {
              return cellValue ? 'Yes' : 'No'
            }
          },
          {
            prop: 'options.acceptedTerms',
            label: 'accepted_Terms',
            minWidth: 160,
            formatter: (row, column, cellValue) => {
              return new Date(cellValue).toLocaleString()
            }
          },

          {
            prop: 'createdAt',
            label: 'Created',
            sortable: true,
            minWidth: 150,
            formatter: (row, column, cellValue) => {
              return new Date(cellValue).toLocaleString()
            }
          },
          {
            prop: 'updatedAt',
            label: 'Updated',
            sortable: true,
            minWidth: 150,
            formatter: (row, column, cellValue) => {
              return new Date(cellValue).toLocaleString()
            }
          },

        ]
      }
    },
    mounted() {},
    computed: {},
    methods: {
      inviteUsers() {
        Swal.fire({
          title: "Invite persons to register",
          text: "",
          icon: "info",
          input: 'textarea',
          inputPlaceholder: "Enter emails separated by newline",
          inputAttributes: {
            "aria-label": "Type your email here"
          },
          inputValidator: value => {
            let stringValues = String(value).trim();

            if (!stringValues) {
              return "Please enter at least one email";
            }
            const valueArray = stringValues.split("\n");
            const emailArray = [];

            for (const entry of valueArray) {
              const trimmedEntry = entry.trim();
              // if empty line
              if(!trimmedEntry) {
                return "Please do not enter empty lines";
              }
              // if already in array
              if(emailArray.includes(trimmedEntry)) {
                return "Please do not enter duplicate emails such as " + entry + " twice";
              }

              let check = String(trimmedEntry)
                .toLowerCase()
                .match(
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );

              if (!check) {
                return "Please enter a valid email for entry: " + entry;
              }
              emailArray.push(trimmedEntry);
            }

          },
          showCancelButton: true,
          confirmButtonText: "Invite",
          showLoaderOnConfirm: true,
          allowOutsideClick: () => !Swal.isLoading()
        }).then(result => {
          //Logger.debug("result", result);
          //Logger.debug("entry", entry);
          if (result.value) {
            //this.endGame();
            console.log("result", result);
            const stringValues = String(result.value).trim().replace(/\s+/g, "");
            const valueArray = stringValues.split("\n");

            let dataObj = {
              recipients: valueArray
            };
            //Logger.debug("dataObj", dataObj);
            this.LoadingX = true;
            adminAPI
              .invite(dataObj)
              .then(response => {
                if (!response) {
                  throw new Error(response.statusText);
                }
                Swal.fire({
                  title: "Invitation sent",
                  html:
                    "FCX Invitation email to entered emails was sent."
                });
              })
              .catch(error => {
                Swal.showValidationMessage(`Request failed: ${error}`);
              })
              .finally(() => {
                this.LoadingX = false;
              });
          }
        });
      }
    }
  }

</script>
<style>
</style>
