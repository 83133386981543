<template>
    <div class="row">
      <div class="col-12">
        <h3>Chem Functions</h3>
      </div>
      <div class="col-md-6">
        <Importer store-import-action="IMPORT_CHEMFUNCTIONS"></Importer>
      </div>
      <div class="col-md-6">
        <Exporter store-import-action="EXPORT_CHEMFUNCTIONS" :query="Query"></Exporter>
      </div>

      <QueryTable
        title="Chemical Families"
        :store-actions="{fetch: 'GET_CHEMFUNCTIONS', delete: 'REMOVE_CHEMFUNCTION'}"
        document-name="ChemFunction"
        detail-path="/chemFunctions/details/"
        :table-columns="tableColumns"
        :functions="{edit: false, delete: true}"
        :query="Query"
      ></QueryTable>
    </div>
</template>
<script>

  import QueryTable from "@/components/FCXComponents/QueryTable.vue";
  import Importer from "@/components/FCXComponents/Importer.vue";
  import Exporter from "@/components/FCXComponents/Exporter.vue";
  import {queryMixin} from "@/js/mixins/query-mixin";

  export default {
    components: {
      Exporter,
      Importer,
      QueryTable
    },
    mixins: [queryMixin],
    data () {
      return {
        propsToSearch: ['_id', 'casId', 'name', 'category1','category2', 'refUserId', 'refSourceId', 'createdAt', 'updatedAt'],
        tableColumns: [
          {
            prop: '_id',
            label: 'ID',
            minWidth: 230
          },
          {
            prop: 'casId',
            label: 'casId',
            sortable: true,
            minWidth: 150
          },
          {
            prop: 'name',
            label: 'name',
            sortable: true,
            minWidth: 250
          },
          {
            prop: 'category1',
            label: 'category1',
            sortable: true,
            minWidth: 200
          },
          {
            prop: 'category2',
            label: 'category2',
            sortable: true,
            minWidth: 200
          },
          {
            prop: 'refUserId',
            label: 'UserId',
            sortable: true,
            minWidth: 230
          },
          {
            prop: 'refSourceId',
            label: 'SourceId',
            sortable: true,
            minWidth: 230
          },
          {
            prop: 'createdAt',
            label: 'Created',
            sortable: true,
            minWidth: 150,
            formatter: (row, column, cellValue) => {
              return new Date(cellValue).toLocaleString()
            }
          },
          {
            prop: 'updatedAt',
            label: 'Updated',
            sortable: true,
            minWidth: 150,
            formatter: (row, column, cellValue) => {
              return new Date(cellValue).toLocaleString()
            }
          },

        ]
      }
    },
    mounted() {},
    computed: {},
    methods: {}
  }

</script>
<style>

</style>
