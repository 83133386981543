<template>
    <div class="row">
      <div class="col-12">
        <h3>Extractions</h3>
      </div>

      <div class="col-md-6">
        <Importer store-import-action="IMPORT_EXTRACTIONS"></Importer>
      </div>
      <div class="col-md-6">
        <Exporter store-import-action="EXPORT_EXTRACTIONS" :query="Query"></Exporter>
      </div>

      <QueryTable
        title="Extractions"
        :store-actions="{fetch: 'GET_EXTRACTIONS', delete: 'REMOVE_EXTRACTION'}"
        document-name="Extraction"
        detail-path="/extractions/details/"
        :table-columns="tableColumns"
        :functions="{edit: false, delete: true}"
        :query="Query"
        >
      </QueryTable>
    </div>
</template>
<script>

  import QueryTable from "@/components/FCXComponents/QueryTable.vue";
  import Importer from "@/components/FCXComponents/Importer.vue";
  import Exporter from "@/components/FCXComponents/Exporter.vue";
  import {queryMixin} from "@/js/mixins/query-mixin";

  export default {
    components: {
      Exporter,
      Importer,
      QueryTable
    },
    mixins: [queryMixin],
    data () {
      return {
        tableColumns: [
          {
            prop: '_id',
            label: 'ID',
            minWidth: 230
          },
          {
            prop: 'sourceKey',
            label: 'sourceKey',
            minWidth: 150
          },
          {
            prop: 'isListed',
            label: 'isListed',
            minWidth: 100,
            formatter: (row, column, cellValue) => {
              return cellValue ? 'Yes' : 'No'
            }
          },
          {
            prop: 'findings',
            label: 'findings (name, label, type, values)',
            minWidth: 350,
            formatter: (row, column, cellValue) => {
              let retString = "";
              for(let entry of cellValue) {
                retString +=  entry.name + ", " + (entry.label || "no label") + ", " + entry.type + ", " + entry.values + "\n";
              }
              return retString;
            }
          },
          {
            prop: 'remarks',
            label: 'remarks',
            minWidth: 250
          },
          {
            prop: 'meta.raw',
            label: 'raw',
            minWidth: 300
          },
          {
            prop: 'comments',
            label: 'comments',
            minWidth: 200
          },
          {
            prop: 'tags',
            label: 'tags',
            minWidth: 200
          },
          {
            prop: 'options.hidden',
            label: 'hidden',
            minWidth: 200
          },
          {
            prop: 'refChemId',
            label: 'UserId',
            minWidth: 230
          },
          {
            prop: 'refSourceId',
            label: 'SourceId',
            minWidth: 230
          },
          {
            prop: 'createdAt',
            label: 'Created',
            sortable: true,
            minWidth: 150,
            formatter: (row, column, cellValue) => {
              return new Date(cellValue).toLocaleString()
            }
          },
          {
            prop: 'updatedAt',
            label: 'Updated',
            sortable: true,
            minWidth: 150,
            formatter: (row, column, cellValue) => {
              return new Date(cellValue).toLocaleString()
            }
          },

        ]
      }
    },
    mounted() {},
    computed: {},
    methods: {}
  }

</script>
<style>
</style>
