//import Swal from "sweetalert2";
//import Worker from "@/js/web/test.worker.js";
//import Worker from "../js/workers/test.worker";
//import Worker from "worker-loader!../js/workers/TestWor.js";
//const Worker = require('Worker!@/js/web/test.worker.js');
//import Worker from 'worker-loader!../js/worker/test.worker.js';
//import Worker from 'worker-loader!./Worker.js';


export const queryMixin = {
  data: function() {
    return {
      query: {},
    };
  },
  created() {

  },
  mounted() {
    this.Query = this.$route.query;
  },
  beforeRouteUpdate(to, from, next) {
    // called when the route that renders this component has changed, but this component is reused in the new route.
    // For example, given a route with params `/users/:id`, when we navigate between `/users/1` and `/users/2`,
    // the same `UserDetails` component instance will be reused, and this hook will be called when that happens.
    // Because the component is mounted while this happens, the navigation guard has access to `this` component instance.
    Logger.debug("beforeRouteUpdate", to, from, this.$route.query);
    Logger.debug("beforeRouteUpdate",this.Query,  to.query);
    this.Query = to.query;
    next();
  },
  computed: {
    Query: {
      get: function () {
        return this.query;
      },
      set: function (newValue) {
        this.query = newValue;
      }

    }
  },
  methods: {

  }
};
